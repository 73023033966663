import { graphql, Link } from 'gatsby'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../../components/Layout/Layout'
import Title from '../../components/Title/Title'
import * as PostStyles from './blog-post.module.css'

function BlogPost({ data }) {
    const { markdownRemark } = data
    const { frontmatter, html } = markdownRemark

    return (
        <Layout>
            <Helmet>
                <title>{frontmatter.title} | Brixworth Osteopathic Clinic</title>
            </Helmet>
            <Title blue={true}>
                <h1>{frontmatter.title}</h1>
                <p>{frontmatter.date}</p>
            </Title>
            <section className={PostStyles.body}>
                <div>
                    <img src={frontmatter.featuredImage} alt={frontmatter.featuredImageAlt} width="900" height="auto" />
                    <div dangerouslySetInnerHTML={{ __html: html}} />
                </div>
                <div className={PostStyles.sidebar}>
                    <div className={PostStyles.recentPosts}>
                        <h3>Recent posts</h3>
                        {
                            data.allMarkdownRemark.nodes.map(node => (
                                <div key={node.id} className={PostStyles.postLink}>
                                    <Link to={`/blog/${node.frontmatter.slug}`}>{node.frontmatter.title}</Link>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export const query = graphql`
    query ($id: String) {
        markdownRemark(id: {eq: $id}) {
            html
            frontmatter {
                featuredImage
                featuredImageAlt
                title
                date(formatString: "DD MMMM YYYY")
                slug
            }
        }
        allMarkdownRemark(sort: {fields: frontmatter___date, order: DESC}, limit: 3) {
            nodes {
                frontmatter {
                    title
                    slug
                }
                id
            }
        }
    }
`

export default BlogPost